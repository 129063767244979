.carousel-mobile {
    overflow: hidden;
  }
  
  .innerp {
    white-space: nowrap;
    transition: transform 0.3s;
    display: flex;
  }
  
  .carousel-celpartner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: #fff;
    
  }
  
  .indicatorsp {
    display: flex;
    justify-content: center;
    gap: 30px;
    width: 100%;
  }
