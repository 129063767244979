.carousel-mobile {
    overflow: hidden;
  }
  
  .innern {
    white-space: nowrap;
    transition: transform 0.3s;
    display: flex;
  }
  
  .carousel-News {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: #fff;
    
  }
  
  .indicatorss {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 100px;
    margin-top: 0%;

    

  }
  
  .indicators > button {
    margin: 5px;
    margin-bottom: 50px;

  }
  