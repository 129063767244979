.carousel {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.innerDesktop {
  white-space: nowrap;
  transition: transform 0.3s;
}

.carousel-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #fff;
}

.indicatorsss {
  display: flex;
  justify-content: space-evenly;
  width: 50%;
  margin-left: 25%;
}

.indicatorsss > button {
  margin: 5px;
  margin-bottom: 40px;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .carousel {
    overflow: hidden;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
